<template>
	<div class="admin-dashboard">
		<h1>Admin dashboard</h1>
		<section class="dashboard-manage three-columns">
			<div class="overview-blocks grid-container">
				<template v-for="item in dashboardItems" v-bind:key="item">
					<div class="block item blue" v-if="can(getAuthData, item.permission) || !item.permission">
						<router-link :to="item.route">
							<div class="block-heading">
								<div class="icon large">
									<i class="fad" v-bind:class="item.icon"></i>
								</div>
								<h3>{{ item.title }}</h3>
							</div>
							<!-- end block heading -->
							<span class="arrow-link">{{ item.linkText }}</span>
						</router-link>
					</div>
				</template>
			</div>
			<!-- end overview-blocks -->
		</section>
	</div>
</template>

<script>
import {mapGetters} from "vuex"
export default {
	data() {
		return {
			dashboardItems: [
				{route: "/admin/clients", title: "Clients", manage: "Clients", icon: "fa-user-tie", linkText: "View clients"},
				{route: "/admin/user-management", title: "User management", permission: "read_other_user_data", icon: "fa-users", linkText: "View users"},
				{route: "/admin/role-management", title: "Role management", permission: "do_everything", icon: "fa-user-crown", linkText: "View roles"},
				{route: "/admin/dev-management", title: "Dev management", permission: "read_all_role_data", icon: "fa-user-crown", linkText: "View settings"},
			],
		}
	},
	computed: {
		...mapGetters("auth", ["getAuthData"]),
	},
}
</script>
